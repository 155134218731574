<template>
  <div class="container">
    <GeneralTable ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="operateTitle='添加', editData={},isAddVisible = true"
      @del-change="deleteItem"
      @export-data="exportData"
      tableName="TypeEquipment"
      >
      <template slot="fileUrl"
        slot-scope="{item}">
        <el-image
            style="width: 32px; height: 32px;border-radius: 50%;"
            :src="item.fileUrl"
            fit="cover"></el-image>
      </template>
      <template slot="isEnabled"
        slot-scope="{item}">
        <div :class="[ 'status', 'status'+item.isEnabled ]">{{ item.isEnabled? '启用' : '停用' }}</div>
      </template>
      <template slot="operate"
        slot-scope="{item}">
        <el-button class="polecy-button"
          @click.native.prevent="editItem(item)"
          v-if="handleHasPerms('M30-TypeEquipment-edit')"
          type="text"
          size="small">编辑</el-button>
        <el-button class="polecy-button polecy-button-no"
          @click.native.prevent="deleteItem([item])"
          v-if="handleHasPerms('M30-TypeEquipment-del')"
          type="text"
          size="small">删除</el-button>
      </template>
    </GeneralTable>
    <customer-add-dialog :dialogVisible.sync="isAddVisible"
      :title="operateTitle"
      :editData="editData"
      :userTypes="userTypes"
      @changeList="changeList" />
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import { customerPage, customerDelete, customerExportExcel, getRelateTreeData, roleBindUser, listGrantedUser, customerBindReferrer, customerResetPwd } from "@/api/SystemData";
import { getEquipmentTypePageList, deleteInsEquipmentType } from "@/api/policy";
import { hasPerms } from "@/utils/auth";
import VDistpicker from "v-distpicker";
import dayjs from 'dayjs';
import {
  dictionaryBatch,
} from "@/api/policy";
import customerAddDialog from "@/views/MeasurementManagement/TypeEquipment/components/AddDialog.vue";
export default {
  data() {
    return {
      option: {
        isAdd: hasPerms('M30-TypeEquipment-add'), //添加 事件 @add-change
        isDel: hasPerms('M30-TypeEquipment-del'), //删除 @del-change
        isEdit: false, // 编辑 @edit-change
        isExport: false,
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        rowPlaceholder: '--',  // 列表数据为空时的显示
        searchList: [
          {
            label: "设备种类",
            prop: "typeName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "更新时间",
          prop: 'updateTime',
          format: 'yyyy.MM.dd',
          formType: 'daterange',
          clearable: true,
          prop: ['updateTimeBegin', 'updateTimeEnd'],
          },
          {
            label: "创建时间",
            prop: 'createTime',
            format: 'yyyy.MM.dd',
            formType: 'daterange',
            clearable: true,
            prop: ['createTimeBegin', 'createTimeEnd'],
          },
        ]
      },
      column: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "设备种类",
          prop: "typeName",
          align: 'center',
          isShow: true,
        },
        // {
        //   label: "大小",
        //   prop: "fileSize",
        //   align: 'center',
        //   isShow: true,
        // },
        {
          label: "图标",
          prop: "fileUrl",
          align: 'center',
          width: '100',
          isShow: true,
          isSlot: true,
        },
        {
          label: "排序",
          prop: "sort",
          align: 'center',
          width: '100',
          isShow: true,
        },
        {
          label: "状态",
          prop: "isEnabled",
          align: 'center',
          isShow: true,
          isSlot: true,
        },
        // {
        //   label: "操作人",
        //   prop: "updateUserName",
        //   align: 'center',
        //   isShow: true,
        // },
        // {
        //   label: "排序",
        //   prop: "sort",
        //   align: 'center',
        //   isShow: true,
        // },
        {
          label: "创建时间",
          prop: "createTime",
          align: 'center',
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          align: 'center',
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 200,
          fixed: 'right',
          isSlot: true,
        },
      ],
      //数据总量
      totalNum: 0,
      claimRecordList: [],
      operateTitle: '添加',
      isAddVisible: false,
      editData: {},
      roleId: '',
      showTreeDialog: false,
      relateTree: [],
      treeSelectKeyList: [],
      treeProps: {
        children: "children",
        label: "title",
        disabled: "selected",
        //员工标识
        type: 2,
        //标识名
        typeName: "type",
        uuidName: "id",
      },
      topPickerData: [],
      oldFormData: {},
      conditionData: {
         regionProvince: '',
         regionCity: '',
         regionArea: ''
      },
      userTypes: [],
      customerIds: [],
      isAllocationCustomer: false,
      isByUserVisible: false,
      passwordType: 'text',
      password: ''
    };
  },
  components: { GeneralTable, VDistpicker, customerAddDialog },
  created() {
  },
  watch: {
  },
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize, searcType, isReset) {
      if (isReset == 'reset') {
         this.conditionData.regionProvince = '';
         this.conditionData.regionCity = '';
         this.conditionData.regionArea = '';
      }
      if (v.createTimeBegin && v.createTimeBegin.split(' ').length === 1) {
        v.createTimeBegin = dayjs(v.createTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.createTimeEnd = dayjs(v.createTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (v.updateTimeBegin && v.updateTimeBegin.split(' ').length === 1) {
        v.updateTimeBegin = dayjs(v.updateTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.updateTimeEnd = dayjs(v.updateTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      let formData = { ...v, ...this.conditionData, current: pageNum, size: pageSize, fastLike: v.keyword }
      this.oldFormData = JSON.parse(JSON.stringify(formData))
      const { code, data } = await getEquipmentTypePageList(formData);
      if (code === 200) {
        this.claimRecordList = data.records;
        this.totalNum = data.total;
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        this.$confirm('删除该设备类型后相关数据会丢失，确认删除？', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = e.map(val => val.id).toString();
          deleteInsEquipmentType({
            ids
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.changeList();
          }).catch(err => {
            this.$message({
              type: 'error',
              message: '失败!'
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的设备种类！');
      }
    },
    // 编辑
    editItem(e) {
      this.editData = e;
      this.operateTitle = '编辑设备种类-' + e.typeName;
      this.isAddVisible = true;
    },
    handleAddUser() {
      this.editData = {};
      this.operateTitle = '添加banner';
      this.isAddVisible = true;
    },
    // 跳页面
    handleGoPath(path, item) {
      let ids = [];
      let name = '';
      let tenantId = '';
      if (item) {
        ids = item.userId;
        name = item.roleName;
        tenantId = item.tenantId;
      } else if (this.$refs.GeneralTable.tableSelectionList && this.$refs.GeneralTable.tableSelectionList.length) {
        ids = this.$refs.GeneralTable.tableSelectionList.map(val => val.userId).toString();
        name = this.$refs.GeneralTable.tableSelectionList.map(val => val.roleName).toString();
      } else {
        this.$message.warning('请选择需要设置的角色！');
        return false;
      }
      this.$router.push({
        path: `${ path }/${ ids }/${ name }/${ tenantId }`,
      });
    },
    exportData(e) {
      // if (!e || !e.length) {
      //   this.$message.warning('请选择需要导出的角色！');
      //   return false;
      // }
      customerExportExcel({
         ...this.oldFormData,
        ids: e.map(val => val.userId)
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
  }
}
</script>

<style lang="scss" scoped>
.reset-password {
  display: flex;
  align-items: center;
}
    .status {
      position: relative;
      padding-left: 18px;
      font-size: 14px;
      display: inline-block;
    }
    .status::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .status1 {
      color: #00bc0d;
    }
    .status1::after {
      background-color: #00bc0d;
    }
    .status0 {
      color: #C94242;
    }
    .status0::after {
      background-color: #C94242;
    }
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: '';
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
  }
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>